<template>
  <div class="register">
    <video class="video" ref="video">
      <source :src="video" />
    </video>
    <div class="button-wrapper">
      <template v-if="currentStep === 'play' && showButton">
        <button class="play-button" @click="playVideo">
          <md-icon class="md-size-3x">play_circle</md-icon>
        </button>
      </template>
      <transition name="fade">
        <div class="fullscreen" v-if="currentStep === 'menu'">
            <div>
              <md-button @click="currentStep = 'post'">By Post</md-button>
              <md-button @click="currentStep = 'email'">By Email</md-button>
            </div>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="currentStep === 'email'">
            <form-validation
              type="email"
              :datetime="{ date, time }"
              @back="() => (currentStep = 'time')"
              @finish="() => (currentStep = 'finsih')"
            />
        </div>
      </transition>
      <div v-if="currentStep === 'post'">
        <transition name="fade">
          <form-validation
            type="address"
            :datetime="{ date, time }"
            @back="() => (currentStep = 'time')"
            @finish="() => (currentStep = 'finsih')"
          />
        </transition>
      </div>
    </div>
    <transition name="fade">
      <booking-time-table
        v-if="currentStep === 'time'"
        @select-time="selectTime"
      />
    </transition>
    <button v-if="currentStep === 'play'" class="skip-btn" @click="onVideoEnd">
        <div :style="{ margin: '10px' }">\Skip</div>
      </button>
  </div>
</template>

<script>
import video from "@/assets/videos/intro.mov";
import FormValidation from "@/components/FormValidation.vue";
import BookingTimeTable from "@/components/BookingTimeTable.vue";

export default {
  components: { FormValidation, BookingTimeTable },
  name: "Register",
  data() {
    return {
      video,
      isPlaying: false,
      isMuted: true,
      showButton: true,
      currentStep: "play",
      init: false
    };
  },
  async mounted() {
    const vm = this;
    try {
      this.$refs.video.play();
      this.isPlaying = true;
      this.showButton = false;
    } catch (error) {
      console.log(error);
      this.showButton = true;
    }

    this.$refs.video.addEventListener("ended", function() {
      vm.onVideoEnd();
    });
  },
  methods: {
    onVideoEnd() {
      this.$refs.video.pause();
      this.currentStep = "time";
      this.showButton = true;
    },
    playVideo() {
      try {
        this.$refs.video.play();
        this.isPlaying = true;
        this.showButton = false;
      } catch (error) {
        console.log(error);
        this.showButton = true;
      }
    },
    selectTime({ date, time }) {
      this.date = date;
      this.time = time;
      this.currentStep = "menu";
    }
  }
};
</script>

<style lang="scss" scoped>
.register {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  background: black;
  .video {
    min-width: 100%;
  }

  button {
    font-family: "Poiret One", cursive;
  }
  .button-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    color: white;
    font-size: 24px;

    input {
      color: white;
    }

    .play-button {
      border: none;
      background: none;
      box-shadow: none;
    }
  }

  .skip-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 40px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    color: white;
    font-size: 28px;
    text-align: center;
    border: none;
    cursor: pointer;

    @media screen and (max-width: 600px) {
      padding: 10px;
    }
  }

  .fullscreen {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
