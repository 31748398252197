import * as THREE from "three";

export const load = async json => {
  const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  const loader = new THREE.ObjectLoader();
  var project = json.project;

  if (project.vr !== undefined) renderer.xr.enabled = project.vr;
  if (project.shadows !== undefined) {
    renderer.shadowMap.enabled = project.shadows;
  }
  if (project.shadowType !== undefined) {
    renderer.shadowMap.type = project.shadowType;
  }
  if (project.toneMapping !== undefined) {
    renderer.toneMapping = project.toneMapping;
  }
  if (project.toneMappingExposure !== undefined) {
    renderer.toneMappingExposure = project.toneMappingExposure;
  }
  if (project.physicallyCorrectLights !== undefined) {
    renderer.physicallyCorrectLights = project.physicallyCorrectLights;
  }

  const scene = loader.parse(json.scene);
  const camera = loader.parse(json.camera);

  scene.background = null;

  // Set Orbit Contorls
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setClearAlpha(0);
  renderer.outputEncoding = THREE.sRGBEncoding;

  const events = {
    init: [],
    start: [],
    stop: [],
    keydown: [],
    keyup: [],
    pointerdown: [],
    pointerup: [],
    pointermove: [],
    update: []
  };

  var scriptWrapParams = "player,renderer,scene,camera";
  var scriptWrapResultObj = {};

  for (var eventKey in events) {
    scriptWrapParams += "," + eventKey;
    scriptWrapResultObj[eventKey] = eventKey;
  }

  var scriptWrapResult = JSON.stringify(scriptWrapResultObj).replace(/"/g, "");

  for (var uuid in json.scripts) {
    var object = scene.getObjectByProperty("uuid", uuid, true);

    if (object === undefined) {
      console.warn("APP.Player: Script without object.", uuid);
      continue;
    }

    var scripts = json.scripts[uuid];

    for (var i = 0; i < scripts.length; i++) {
      var script = scripts[i];

      // eslint-disable-next-line no-new-func
      var functions = new Function(
        scriptWrapParams,
        script.source + "\nreturn " + scriptWrapResult + ";"
      ).bind(object)(this, renderer, scene, camera);

      for (var name in functions) {
        if (functions[name] === undefined) continue;

        if (events[name] === undefined) {
          console.warn("APP.Player: Event type not supported (", name, ")");
          continue;
        }

        events[name].push(functions[name].bind(object));
      }
    }
  }

  dispatch(events.init);

  return { camera, scene, renderer, events };
};

export const dispatch = (array, event) => {
  for (var i = 0, l = array.length; i < l; i++) {
    array[i](event);
  }
};
