import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueScrollTo from "vue-scrollto";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueMaterial from "vue-material";
import { auth } from "./firebase";

import "vue-material/dist/vue-material.min.css";
import "@/style/theme.scss";

Vue.use(VueMaterial);

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  lazy: true,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

let app;
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }

  if (user) {
    store.dispatch("fetchUserProfile", user);
  }
});
