<template>
  <div class="key">
    <div v-if="id">Address:{{ id }}</div>
    <div v-else>
      Oops, you got lost:( <br />please click the link in the Email or scan the QR-Code
      again
    </div>
  </div>
</template>

<script>
export default {
  name: 'Key',
  computed: {
    id() {
      return this.$store.state.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.key {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-family: 'Poiret One', cursive;
  background: black;
  width: 100vw;
  height: 100vh;
  padding: 30px;
  font-size: 24px;
  line-height: 1.5;
}
</style>
