<template>
  <div class="video-section video-wrapper" @mouseup="endDrop">
    <video class="video" :muted="isMuted" ref="video">
      <source :src="video" />
    </video>
    <div class="header">
      <button class="btn" ref="contact" @click="onContact">\Contact</button>
      <button class="btn" ref="about" @click="onRegister">\Register</button>
    </div>
    <div class="footer">
      <button class="left" @click="playVideo">
        <EyeClosed v-if="!isPlaying" class="eye-icon" />
        <EyeOpen v-else class="eye-icon" />
      </button>
      <button class="btn" @click="onNext">
        <div :style="{ margin: '10px' }">\Skip</div>
        <img
          :src="skipButton"
          alt="skip button"
          :style="{ width: '60px', height: '20px' }"
        />
      </button>
      <div class="audio-controller">
        <div class="volume-dragger" @mousedown="beginDrop" @mousemove="onDrop">
          <div class="volume-bar">
            <div class="volume-ball" ref="volume-ball"></div>
          </div>
        </div>
        <button class="bg-music-btn" @click="toggleMute">
          <VolumeUp v-if="!isMuted" class="eye-icon" />
          <VolumeMute v-else class="eye-icon" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import WordShuffler from "../helpers/Letters";
import video from "@/assets/videos/imrx.mov";
import skipButton from "@/assets/imgs/skip-button.png";
import EyeOpen from "@/assets/icons/eye-open.svg";
import EyeClosed from "@/assets/icons/eye-closed.svg";
import VolumeUp from "@/assets/icons/volume-up.svg";
import VolumeMute from "@/assets/icons/volume-mute.svg";

export default {
  name: "VideoSection",
  components: {
    EyeOpen,
    EyeClosed,
    VolumeUp,
    VolumeMute
  },
  data() {
    return {
      video,
      skipButton,
      isPlaying: true,
      isMuted: true,
      drop: false
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    }
  },
  watch: {
    isLoading(value) {
      if (!value) {
        let contact = this.$refs.contact;
        let about = this.$refs.about;
        new WordShuffler(contact, {
          textColor: "#fff",
          timeOffset: 1,
          mixCapital: true,
          mixSpecialCharacters: true
        });
        new WordShuffler(about, {
          textColor: "#fff",
          timeOffset: 1
        });
        this.$refs.video.play();
      }
    }
  },
  mounted() {
    const videoRef = this.$refs.video;
    videoRef.addEventListener("ended", function() {
      videoRef.currentTime = 1;
      videoRef.pause();
    });
  },
  methods: {
    toggleMute() {
      this.isMuted = !this.isMuted;
    },
    beginDrop(e) {
      const top = e.offsetY - 15;
      this.drop = true;
      this.setVolume(top);
    },
    endDrop() {
      this.drop = false;
    },
    onDrop(e) {
      const top = e.offsetY - 15;
      this.setVolume(top);
    },
    setVolume(top) {
      if (this.drop && top >= -5 && top <= 75) {
        console.log(top);
        this.$refs["volume-ball"].style.top = top + "px";
        console.log((75 - top) / 100);
        this.$refs.video.volume = (75 - top) / 100;
      }
    },
    onNext() {
      this.$emit("to-next");
    },
    onRegister() {
      this.$router.push("/register");
    },
    onContact() {
      this.$emit("to-contact");
    },
    playVideo() {
      if (!this.isPlaying) {
        this.$refs.video.play();
        this.isPlaying = true;
      } else {
        this.$refs.video.pause();
        this.isPlaying = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.video-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  font-family: "Poiret One", cursive;
  position: relative;
  button {
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    color: #ffffff;
    text-align: center;
    background: none;
    font-family: "Poiret One", cursive;
    cursor: pointer;
  }

  button:focus {
    outline: none;
  }
  .btn {
    font-size: 28px;
  }
  .video {
    min-width: 100%;
  }
  .header {
    padding: 40px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 600px) {
      padding: 10px;
    }
  }
  .footer {
    position: absolute;
    width: 100%;
    padding: 40px;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media screen and (max-width: 600px) {
      padding: 10px;
    }
    .eye-icon {
      width: 40px;
      height: 40px;
    }
    .audio-controller {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .bg-music-btn {
        background-color: transparent;
        color: white;
        border: none;
        outline: none;
        font-size: 18px;
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
      .volume-dragger {
        width: 40px;
        padding-bottom: 20px;
        height: 90px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .volume-bar {
          border-radius: 1.5px;
          width: 1px;
          height: 80px;
          background-color: white;
          position: relative;
          .volume-ball {
            width: 10px;
            height: 10px;
            background-color: white;
            border-radius: 5px;
            position: absolute;
            left: -4.5px;
          }
        }
      }
    }
  }
}
</style>
