<template>
  <div class="home">
    <Loading :active.sync="isLoading" :is-full-page="true" />
    <div class="home__cursor-horizontal" ref="lineHorizontal" />
    <div class="home__cursor-vertical" ref="lineVertical" />

    <video-section
      @to-next="scrollNext"
      @to-about="scrollAbout"
      @to-contact="scrollContact"
    />
    <div
      class="content"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <Canvas />
      <div class="section" ref="intro">
        <intro-section v-if="showIntro" />
      </div>
      <div class="section game" ref="game">
        <transition name="fade">
          <game-section v-if="showGame" />
        </transition>
      </div>
      <div class="section about" ref="about">
        <transition name="fade">
          <about-section v-if="showAbout" />
        </transition>
      </div>
      <div ref="contact">
        <div class="footer">©️ COPYRIGHT 2021 IMRX. ALL RIGHTS RESERVED</div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import IntroSection from "../components/IntroSection.vue";
import VideoSection from "../components/VideoSection.vue";
import backgroundImage from "@/assets/imgs/background-1.png";
import AboutSection from "../components/AboutSection.vue";
import Canvas from "../components/Canvas.vue";
import GameSection from "../components/GameSection.vue";

// @ is an alias to /src
export default {
  name: "Home",
  components: {
    VideoSection,
    IntroSection,
    AboutSection,
    Canvas,
    GameSection,
    Loading
  },
  data() {
    return {
      backgroundImage,
      showIntro: false,
      showGame: false,
      showAbout: false,
      horizontalLength: 0,
      verticalLength: 0,
      timeout: undefined
    };
  },
  props: {
    id: {
      type: String || null,
      default: null
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("mousemove", this.cursorPos);
    if (this.id) this.$store.dispatch("setId", this.id);
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("mousemove", this.cursorPos);
  },
  methods: {
    cursorPos(e) {
      if (this.timeout) clearTimeout(this.timeout);
      const movement = (Math.abs(e.movementX) + Math.abs(e.movementY)) / 2;

      const left = e.clientX;
      const top = e.clientY;

      this.horizontalLength += movement;
      this.verticalLength += movement;

      this.$refs.lineHorizontal.style.width = this.horizontalLength + "px";
      this.$refs.lineVertical.style.height = this.verticalLength + "px";

      this.$refs.lineHorizontal.style.top = top + "px";
      this.$refs.lineVertical.style.left = left + "px";

      this.$refs.lineHorizontal.style.left =
        left - this.horizontalLength / 2 + "px";
      this.$refs.lineVertical.style.top = top - this.verticalLength / 2 + "px";

      this.timeout = setTimeout(() => {
        const currentTop = top;
        const currentLeft = left;
        gsap.to(this.$refs.lineVertical, {
          height: 0,
          top: currentTop,
          onComplete: () => {
            this.verticalLength = 0;
          }
        });
        gsap.to(this.$refs.lineHorizontal, {
          width: 0,
          left: currentLeft,
          onComplete: () => {
            this.horizontalLength = 0;
          }
        });
      }, 100);
    },
    handleScroll() {
      this.currrentScroll = window.scrollY;
      if (
        this.$refs.intro &&
        window.scrollY >= this.$refs.intro.offsetTop - 200
      )
        this.showIntro = true;
      if (this.$refs.game && window.scrollY >= this.$refs.game.offsetTop - 200)
        this.showGame = true;
      if (
        this.$refs.about &&
        window.scrollY >= this.$refs.about.offsetTop - 200
      )
        this.showAbout = true;
    },
    scrollNext() {
      this.showIntro = false;
      this.$scrollTo(this.$refs.intro, 1000);
    },
    scrollAbout() {
      this.showAbout = false;
      this.$scrollTo(this.$refs.about, 1000);
    },
    scrollContact() {
      this.$scrollTo(this.$refs.contact, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  overflow: hidden;
  background-color: black;
  cursor: none;
  .section {
    width: 100%;
    min-height: 120vh;
    margin-bottom: 200px;
    z-index: 2;
    &.game {
      position: relative;
    }
    &.about {
      min-height: 100vh;
      margin-bottom: 0;
    }
  }
  .footer {
    color: rgba(255, 255, 255, 0.616);
    font-size: 12px;
    padding-bottom: 100px;
  }
  .content {
    position: relative;
    animation: parallax_fg linear 60s infinite both;
    padding-top: 400px;
    background-size: cover;
    background-repeat: repeat-y;
  }

  &__cursor-horizontal {
    position: fixed;
    background-color: white;
    height: 1px;
    z-index: 3;
    box-shadow: 0 0 5px white, 1px 1px 5px white, 2px 2px 5px white,
      -1px -1px 5px white, -2px -2px 5px white;

    pointer-events: none;
  }
  &__cursor-vertical {
    position: fixed;
    background-color: white;
    width: 1px;
    z-index: 3;
    box-shadow: 0 0 5px white, 1px 1px 5px white, 2px 2px 5px white,
      -1px -1px 5px white, -2px -2px 5px white;
    pointer-events: none;
  }
}

@keyframes parallax_fg {
  0% {
    background-position-y: 3000px;
    background-size: cover;
  }
  100% {
    background-position-y: 0;
    background-size: cover;
  }
}
</style>
