<template>
  <div class="booking-time-table">
    <div class="time-table-wrapper">
      <h1>Pick a time</h1>
      <div class="time-table">
        <div v-for="date of dates" :key="date.label" class="date-col">
          <div class="title">
            {{ date.label }}
          </div>
          <template v-if="date.timestamp >= today">
            <md-button
              v-for="block of time"
              :key="block.label"
              class="button"
              @click="selectTime({ date: date.label, time: block.value })"
              >{{ block.value }}</md-button
            >
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import time from "@/assets/json/range.js";
import moment from "moment";

export default {
  name: "BookingTimeTable",
  data() {
    return {
      time,
      dates: [
        { date: "MON" },
        { date: "TUE" },
        { date: "WED" },
        { date: "THU" },
        { date: "FRI" },
        { date: "SAT" },
        { date: "SUN" }
      ],
      today: new Date("2021-10-29")
    };
  },
  created() {
    const curr = new Date("2021-10-29"); // get current date
    const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    for (var i = 0; i < 7; i++) {
      const timestamp = new Date(curr.setDate(first + i + 1)).getTime();
      this.dates[i] = { label: moment(timestamp).format("DD.MM"), timestamp };
    }
  },
  methods: {
    selectTime({ date, time }) {
      this.$emit("select-time", { date, time });
    }
  }
};
</script>

<style lang="scss" scoped>
.booking-time-table {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poiret One", cursive;

  .time-table-wrapper {
    background: linear-gradient(
      18deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(88, 88, 88, 1) 11%,
      rgba(152, 152, 152, 0.4) 24%,
      rgba(0, 0, 0, 1) 53%,
      rgba(69, 69, 69, 0.4) 100%
    );
    padding: 30px 30px 80px 30px;
    display: flex;
    width: 60%;
    justify-content: center;
    flex-direction: column;

    h1 {
      margin: 30px;
      color: white;
    }

    @media screen and (max-width: 900px) {
        width: 95%;
      }
  }

  .time-table {
    display: flex;
    width: 100%;
    flex-grow: 1;
    justify-content: space-around;
  }

  .title {
    margin-bottom: 12px;
    color: white;
    font-size: 24px;
  }

  .date-col {
    display: flex;
    flex-direction: column;
  }

  .button {
    width: 80px;
    white-space: wrap;
    text-align: center;
    margin-left: 6px;
  }
}
</style>
