import Vue from "vue";
import Vuex from "vuex";
import * as fb from "../firebase";
import router from "../router/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: true,
    id: null,
    userProfile: {}
  },
  mutations: {
    TOGGLE_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_ID(state, payload) {
      state.id = payload;
    },
    setUserProfile(state, val) {
      state.userProfile = val;
    }
  },
  actions: {
    setLoading: ({ commit }, payload) => {
      commit("TOGGLE_LOADING", payload);
    },
    setId: ({ commit }, payload) => {
      commit("SET_ID", payload);
    },
    async signup({ dispatch }, { email, password, profile }) {
      // sign user up
      const { user } = await fb.auth.createUserWithEmailAndPassword(
        email,
        password
      );
      await fb.usersCollection.doc(user.uid).set({
        ...profile
      });

      dispatch("fetchUserProfile", user);

      return user;
    },
    async login({ dispatch }, form) {
      const { user } = await fb.auth.signInWithEmailAndPassword(
        form.email,
        form.password
      );

      dispatch("fetchUserProfile", user);
    },
    async fetchUserProfile({ commit }, user) {
      const userProfile = await fb.usersCollection.doc(user.uid).get();

      commit("setUserProfile", userProfile.data());

      // change route to dashboard
      if (router.currentRoute.path === "/login") {
        router.push("/key");
      }
    },
    async logout({ commit }) {
      await fb.auth.signOut();

      // clear userProfile and redirect to /login
      commit("setUserProfile", {});
      router.push("/login");
    },
    async updateProfile({ dispatch }, user) {
      const userId = fb.auth.currentUser.uid;
      // update user object
      await fb.usersCollection.doc(userId).update({
        ...user
      });

      dispatch("fetchUserProfile", { uid: userId });
    }
  },
  modules: {}
});
