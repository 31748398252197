<template>
  <div class="form">
    <form novalidate class="md-layout" @submit.prevent="validateUser">
      <md-card class="md-layout-item md-size-100">
        <md-card-header>
          <div class="md-title">Registration</div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-100">
              <md-field :class="getValidationClass('email')">
                <label for="email">Email</label>
                <md-input
                  type="email"
                  name="email"
                  id="email"
                  autocomplete="email"
                  v-model="form.email"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.email.required"
                  >The email is required</span
                >
                <span class="md-error" v-else-if="!$v.form.email.email"
                  >Invalid email</span
                >
              </md-field>
              <md-field :class="getValidationClass('password')">
                <label for="password">Password</label>
                <md-input
                  type="password"
                  name="password"
                  id="password"
                  v-model="password"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.password.required">
                  The password is required
                </span>
                <span class="md-error" v-else-if="!$v.password.minlength"
                  >Password should be at least 6 characters</span
                >
              </md-field>
              <md-field :class="getValidationClass('password')">
                <label for="password2">Confirm Password</label>
                <md-input
                  type="password"
                  name="password2"
                  id="password2"
                  v-model="password2"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.password2.required">
                  The password confirmation is required
                </span>
                <span class="md-error" v-else-if="!$v.password2.checkPAssword"
                  >Password should be at least 6 characters</span
                >
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-100">
              <md-field :class="getValidationClass('firstName')">
                <label for="first-name">First Name</label>
                <md-input
                  name="first-name"
                  id="first-name"
                  autocomplete="given-name"
                  v-model="form.firstName"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.firstName.required"
                  >The first name is required</span
                >
                <span class="md-error" v-else-if="!$v.form.firstName.minlength"
                  >Invalid first name</span
                >
              </md-field>
            </div>

            <div class="md-layout-item md-size-100">
              <md-field :class="getValidationClass('lastName')">
                <label for="last-name">Last Name</label>
                <md-input
                  name="last-name"
                  id="last-name"
                  autocomplete="family-name"
                  v-model="form.lastName"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.lastName.required"
                  >The last name is required</span
                >
                <span class="md-error" v-else-if="!$v.form.lastName.minlength"
                  >Invalid last name</span
                >
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-100">
              <md-field :class="getValidationClass('age')">
                <label for="age">Age</label>
                <md-input
                  type="number"
                  id="age"
                  name="age"
                  autocomplete="age"
                  v-model="form.age"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.form.age.required"
                  >The age is required</span
                >
                <span class="md-error" v-else-if="!$v.form.age.maxlength"
                  >Invalid age</span
                >
              </md-field>
            </div>
          </div>
          <template v-if="type === 'address'">
            <md-field :class="getValidationClass('address')">
              <label for="address">Address</label>
              <md-input
                type="address"
                name="address"
                id="address"
                autocomplete="address"
                v-model="form.address"
                :disabled="sending"
              />
              <span class="md-error" v-if="!$v.form.address.required"
                >The address is required</span
              >
            </md-field>
            <md-field :class="getValidationClass('postcode')">
              <label for="postcode">Postcode</label>
              <md-input
                type="postcode"
                name="postcode"
                id="postcode"
                autocomplete="postcode"
                v-model="form.postcode"
                :disabled="sending"
              />
              <span class="md-error" v-if="!$v.form.postcode.required"
                >The postcode is required</span
              >
              <span class="md-error" v-else-if="!$v.form.postcode.minLength"
                >Invalid postcode</span
              >
            </md-field>
            <md-field :class="getValidationClass('country')">
              <label for="country">Country</label>
              <md-input
                type="country"
                name="country"
                id="country"
                autocomplete="country"
                v-model="form.country"
                :disabled="sending"
              />
              <span class="md-error" v-if="!$v.form.country.required"
                >The country is required</span
              >
            </md-field>
          </template>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending" />

        <md-card-actions>
          <md-button
            @click="() => this.$emit('back')"
            class="md-primary"
            :disabled="sending"
            >Cancel</md-button
          >
          <md-button
            type="submit"
            class="md-primary"
            :disabled="sending"
            @click="saveUser"
            >Submit</md-button
          >
        </md-card-actions>
      </md-card>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";

import {
  required,
  email,
  minLength,
  maxLength,
  sameAs
} from "vuelidate/lib/validators";

export default {
  name: "FormValidation",
  mixins: [validationMixin],
  data: () => ({
    form: {
      date: null,
      firstName: null,
      lastName: null,
      age: null,
      email: null,
      address: null,
      postcode: null,
      country: null
    },
    password: null,
    password2: null,
    sending: false
  }),
  props: {
    type: {
      type: String,
      required
    },
    datetime: {
      type: Object,
      required
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6)
    },
    password2: {
      required,
      checkPassword: sameAs("password")
    },
    form: {
      firstName: {
        required,
        minLength: minLength(3)
      },
      lastName: {
        required,
        minLength: minLength(3)
      },
      age: {
        required,
        maxLength: maxLength(3)
      },
      email: {
        required,
        email
      },
      address: {
        required
      },
      postcode: {
        required,
        numeric: true,
        minLength: minLength(5)
      },
      country: {
        required
      }
    }
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty
        };
      }
    },
    clearForm() {
      this.$v.$reset();
      this.form.date = null;
      this.form.firstName = null;
      this.form.lastName = null;
      this.form.age = null;
      this.form.email = null;
      this.form.address = null;
      this.form.postcode = null;
      this.form.country = null;
    },
    async saveUser() {
      this.sending = true;
      await this.$store.dispatch("signup", {
        email: this.form.email,
        password: this.password,
        profile: { ...this.form, date: this.date, time: this.time }
      });
      this.$emit("finish");
    },
    validateUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveUser();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  .md-card {
    background-color: black;
  }
}
.md-icon.md-theme-default.md-icon-image svg {
  color: white;
  fill: white;
}
.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
</style>
