import firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyDW9CqScfuX8l1WDec8JT6Y9pYypMC7t-g",
  authDomain: "imrx-319409.firebaseapp.com",
  projectId: "imrx-319409",
  storageBucket: "imrx-319409.appspot.com",
  messagingSenderId: "783572537926",
  appId: "1:783572537926:web:edc86a1b100a927368f047",
  measurementId: "G-52BPZE3VBB"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
// utils
const db = firebase.firestore();
const auth = firebase.auth();

// collection references
const usersCollection = db.collection("users");

// export utils/refs
export { db, auth, usersCollection };
