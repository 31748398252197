<template>
  <div ref="container" class="about-section">
    <div class="title">
      <span>IMR</span>
      <img :src="xIcon" class="title-x" />
    </div>
    <div ref="context" class="content">
      <span ref="paragraph-one">
        Founded in 2020, IMRX is a new media company that focuses on immersive interactive
        experience, mixing virtual and reality technologies to create the aesthetics of
        future space.
      </span>
      <span ref="paragraph-two">
        We are committed to the unlimited development of space and the re-weaving of
        virtual and reality. Use state-of-art technologies to shape an immersive and
        exciting future.
      </span>
    </div>
    <div ref="social-icons" class="social-icon-wrapper">
      <a href="https://www.instagram.com/imrx_io/"
        ><InstagramIcon class="social-icon"
      /></a>
      |
      <a><YoutubeIcon class="social-icon" /></a>
    </div>
  </div>
</template>

<script>
import YoutubeIcon from "../assets/icons/youtube.svg";
import InstagramIcon from "../assets/icons/instagram.svg";
import xIcon from "../assets/icons/x.gif";
export default {
  name: "AboutSection",
  data() {
    return {
      xIcon,
    };
  },
  components: {
    InstagramIcon,
    YoutubeIcon,
  },
};
</script>

<style lang="scss" scoped>
.about-section {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Poiret One", cursive;
  overflow: hidden;
  
  .title {
    font-size: 48px;
    margin-bottom: 20px;
    vertical-align: middle;
    display: flex;
    text-shadow: 0 0 10px white, 1px 1px 10px white, 2px 2px 10px white,
      -1px -1px 10px white, -2px -2px 10px white;
    .title-x {
      height: 72px;
      object-fit: contain;
      transform: translateY(-25px);
    }
  }
  .content {
    width: 80%;
    font-size: 30px;
    z-index: 2;
  }
  span {
    color: white;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    /* font-family: "Raleway", sans-serif; */
    font-weight: 100;
    display: block; 
    z-index: 2;
  }
  .social-icon-wrapper {
    font-size: 35px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .social-icon {
    margin: 20px;
    height: 30px;
    color: white;
  }
}
</style>
