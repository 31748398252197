export default [
  {
    label: "B",
    value: "11:00 - 11:45"
  },
  {
    label: "C",
    value: "12:00 - 12:45"
  },
  {
    label: "D",
    value: "13:00 - 13:45"
  },
  {
    label: "E",
    value: "14:00 - 14:45"
  },
  {
    label: "F",
    value: "15:00 - 15:45"
  },
  {
    label: "G",
    value: "16:00 - 16:45"
  }
];
