module.exports = function WordShuffler(holder, opt) {
  let that = this;
  this.now;
  this.then = Date.now();

  this.delta;
  this.currentTimeOffset = 0;

  this.word = null;
  this.currentWord = null;
  this.wordsMovingTime = [];
  this.currentWordLength = 0;

  const options = {
    fps: 30,
    timeOffset: 5,
    textColor: "#000",
    fontSize: "50px",
    mixCapital: false,
    mixSpecialCharacters: false,
    needUpdate: true,
    colors: ["#ff0000", "#0000ff", "#ffffff"]
  };

  if (typeof opt != "undefined") {
    for (let key in opt) {
      options[key] = opt[key];
    }
  }

  this.needUpdate = true;
  this.fps = options.fps;
  this.interval = 1000 / this.fps;
  this.timeOffset = options.timeOffset;
  this.textColor = options.textColor;
  this.fontSize = options.fontSize;
  this.mixCapital = options.mixCapital;
  this.mixSpecialCharacters = options.mixSpecialCharacters;
  this.colors = options.colors;

  this.chars = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z"
  ];
  this.specialCharacters = [
    "!",
    "§",
    "$",
    "%",
    "&",
    "/",
    "(",
    ")",
    "=",
    "?",
    "_",
    "<",
    ">",
    "^",
    "°",
    "*",
    "#",
    "-",
    ":",
    ";",
    "~"
  ];

  if (this.mixSpecialCharacters) {
    this.chars = this.chars.concat(this.specialCharacters);
  }

  this.getRandomColor = function() {
    let randNum = Math.floor(Math.random() * this.colors.length);
    return this.colors[randNum];
  };

  this.position = {
    x: 0,
    y: 50
  };

  //if DOM
  if (typeof holder != "undefined") {
    this.holder = holder;
  }

  if (typeof this.holder == "undefined") {
    console.warn(
      "Holder must be defined in DOM Mode. Use Canvas or define Holder"
    );
  }

  this.getRandCharacter = function(characterToReplace) {
    if (characterToReplace == " ") {
      return " ";
    }
    let randNum = Math.floor(Math.random() * this.chars.length);
    let lowChoice = -0.5 + Math.random();
    let picketCharacter = this.chars[randNum];
    let choosen = picketCharacter.toLowerCase();
    if (this.mixCapital) {
      choosen = lowChoice < 0 ? picketCharacter.toLowerCase() : picketCharacter;
    }
    return choosen;
  };

  this.writeWord = function(word) {
    this.word = word;
    this.currentWord = word.split("");
    this.currentWordLength = this.currentWord.length;
    for (let i = 0; i < this.currentWordLength; i++) {
      const randomNum = Math.floor(Math.random() * this.fps);
      this.wordsMovingTime.push(randomNum);
    }
  };

  this.generateSingleCharacter = function(color, character) {
    let span = document.createElement("span");
    span.style.color = color;
    span.innerHTML = character;
    return span;
  };

  this.updateCharacter = function() {
    this.now = Date.now();
    this.delta = this.now - this.then;
    if (this.delta > this.interval) {
      this.currentTimeOffset++;

      var word = [];
      this.needUpdate = false;

      for (let i = 0; i < this.currentWordLength; i++) {
        if (
          this.currentTimeOffset >=
          this.timeOffset * this.fps + this.wordsMovingTime[i]
        ) {
          word.push(this.currentWord[i]);
        } else {
          word.push(this.getRandCharacter(this.currentWord[i]));
          this.needUpdate = true;
        }
      }
      this.holder.innerHTML = "";
      word.forEach(w => {
        var color = null;
        if (this.currentTimeOffset < this.timeOffset * this.fps) {
          color = that.getRandomColor();
        } else {
          color = that.textColor;
        }
        that.holder.appendChild(that.generateSingleCharacter(color, w));
      });
      this.then = this.now - (this.delta % this.interval);
    }
  };

  this.restart = function(updateWork) {
    this.currentTimeOffset = 0;
    this.needUpdate = true;
    if (updateWork) this.writeWord(this.holder.innerHTML);
    update();
  };

  function update() {
    if (that.needUpdate) {
      that.updateCharacter();
    } else {
      cancelAnimationFrame(update);
    }
    requestAnimationFrame(update);
  }
  this.writeWord(this.holder.innerHTML);
  update();
};
