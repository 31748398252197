<template>
  <div class="game-section">
    <div class="block parallel">
      <div class="parallel">
        P
        <div ref="parallel">A</div>
        RALLEL
      </div>
      <span style="float: left; text-align: start">
        In this parallel time and space,<br />
        these people are living at almost the same pace as us,<br />
        but we just don't notice.<br />
      </span>
    </div>
    <div class="block">
        <div class="beta">
        B
        <div ref="beta">E</div>
        TA
        </div>

      <span style="float: right; text-align: end">
        You got the invitation from BETA to join LEEK.<br />
        Beta always enters each new process ahead of the player. <br />
        and you noticed by accident that Beta disappeared.<br />
        So what happened？<br />
      </span>
    </div>
    <div class="block">

      <div class="leekcoin">
        L
        <div ref="leekcoin">E</div>
        EKCOIN
      </div>
      <span style="float: left; text-align: start">
        What secrets are hidden inside the mysterious Leek world?<br />
        Does the disappearance of Beta have anything to do with it?<br />
        Take the mission:<br />
      </span>
    </div>
    <div class="block">
      <div class="metavex">
        META
        <div ref="metavex">V</div>
        EX
      </div>

      <span style="float: right; text-align: end">
        Reality in both digital and analog.<br />
        Asynchrony of vision and touch.<br />
        Multiple lifestyles appear at the same time.<br />
        Welcome to the Metaverse.
      </span>
    </div>
  </div>
</template>

<script>
import WordShuffler from '../helpers/Letters';

export default {
  name: 'GameSection',
  data() {
    return {
      jumpingLetters: [],
    };
  },
  mounted() {
    let parallel = this.$refs.parallel;
    let leekcoin = this.$refs.leekcoin;
    let beta = this.$refs.beta;
    let metavex = this.$refs.metavex;

    this.jumpingLetters[0] = new WordShuffler(parallel, {
      textColor: '#fff',
      timeOffset: 0.2,
      mixCapital: true,
      mixSpecialCharacters: true,
      colors: ['#ffffff'],
    });

    this.jumpingLetters[2] = new WordShuffler(leekcoin, {
      textColor: '#fff',
      timeOffset: 0.2,
      colors: ['#ffffff'],
    });
    this.jumpingLetters[4] = new WordShuffler(beta, {
      textColor: '#fff',
      timeOffset: 0.2,
      mixCapital: true,
      mixSpecialCharacters: true,
      colors: ['#ffffff'],
    });

    this.jumpingLetters[5] = new WordShuffler(metavex, {
      textColor: '#fff',
      timeOffset: 0.2,
      colors: ['#ffffff'],
    });
    for (const letter of this.jumpingLetters) {
      setInterval(() => {
        if(letter) letter.restart(false);
      }, Math.random(10) * 6000 + 3000);
    }
  },
};
</script>

<style lang="scss" scoped>
.game-section {
  font-family: 'Poiret One', cursive;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20vh;
  width: 100%;
  margin-bottom: 200px;
  .block {
    /* margin: 20px; */
    width: 40%;
    z-index: 2;
    color: white;
    padding: 5px;
    margin-bottom: 150px;
    * {
      text-shadow: 0 0 10px white, 1px 1px 10px white, 2px 2px 10px white,
        -1px -1px 10px white, -2px -2px 10px white;
    }
    .parallel {
      font-size: 8vw;
      line-height: 1.2;
      float: left;
      display: flex;
    }
    .beta {
      font-size: 8vw;
      line-height: 1.2;
      float: right;
      display: flex;
    }
    .leekcoin {
      font-size: 8vw;
      line-height: 1.2;
      float: left;
      display: flex;
    }
    .metavex {
      font-size: 8vw;
      line-height: 1.2;
      float: right;
      display: flex;
    }
    span {
      color: white;
      width: 100%;
      border-radius: 50%;
      font-weight: 100;
      display: block;
      text-shadow: none;
      line-height: 1.1;
      font-size: 24px;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      span {
        font-size: 28px;
      }
    }
  }
}
</style>
