<template>
  <div ref="container" class="intro-section">
    <div
      class="intro-section__line"
      :class="{ horizontal: !onMenu }"
      ref="lineHorizontal"
    />
    <div
      class="intro-section__line"
      :class="{ vertical: !onMenu }"
      ref="lineVertical"
    />
    <div ref="content" class="intro-section__content">
      <button @click="onClick('ar')">
        <span ref="ar">AR</span>
      </button>
      <div class="intro-section__content-middle">
        <button @click="onClick('online')">
          <span ref="online">Online</span>
        </button>
        <button @click="onClick('offline')">
          <span ref="offline">Offline</span>
        </button>
      </div>
      <button @click="onClick('mr')">
        <span ref="mr">MR</span>
      </button>
    </div>
    <div class="intro-section__keyword-wrapper">
      <span ref="key-word" class="key-word" @click="onClickKeyword"></span>
    </div>
  </div>
</template>

<script>
import { TimelineLite, Back, gsap } from "gsap";
import WordShuffler from "../helpers/Letters";
import mrImg from "../assets/imgs/mr.jpg";
import arImg from "../assets/imgs/ar.jpg";
import onlineImg from "../assets/imgs/online.jpg";
import offlineImg from "../assets/imgs/offline.png";
export default {
  data() {
    return {
      mrImg,
      arImg,
      onlineImg,
      offlineImg,
      lineHorizontalAnim: new TimelineLite(),
      lineVerticalAnim: new TimelineLite(),
      onMenu: true,
      currentStep: 0,
      letter: {
        ar: "Key",
        mr: "Adventure",
        online: "Virtual",
        offline: "Reality"
      },
      keyWord: undefined
    };
  },
  mounted() {
    setTimeout(() => {
      this.initAnim();
    }, 1000);
    // let ar = this.$refs.ar;
    // let mr = this.$refs.mr;
    // let online = this.$refs.online;
    // let offline = this.$refs.offline;

    // new WordShuffler(ar, {
    //   textColor: '#fff',
    //   timeOffset: 1,
    //   mixCapital: true,
    //   mixSpecialCharacters: true,
    // });

    // new WordShuffler(mr, {
    //   textColor: '#fff',
    //   timeOffset: 1,
    // });

    // new WordShuffler(online, {
    //   textColor: '#fff',
    //   timeOffset: 1,
    //   mixCapital: true,
    //   mixSpecialCharacters: true,
    // });

    // new WordShuffler(offline, {
    //   textColor: '#fff',
    //   timeOffset: 1,
    // });

    this.keyWord = new WordShuffler(this.$refs["key-word"], {
      textColor: "#fff",
      timeOffset: 1
    });
  },
  computed: {
    isMobile() {
      let check = false;
      (function(a) {
        if (
          // eslint-disable-next-line no-useless-escape
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          // eslint-disable-next-line no-useless-escape
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      if (this.width < 600) check = true;
      return check;
    }
  },
  methods: {
    initAnim() {
      this.lineHorizontalAnim.to(this.$refs.lineHorizontal, 0.5, {
        rotation: 45,
        ease: Back.easeInOut // Specify an ease
      });
      this.lineVerticalAnim.to(this.$refs.lineVertical, 0.5, {
        rotation: -45,
        ease: Back.easeInOut // Specify an ease
      });
      this.rotateLine();
      gsap.fromTo(
        this.$refs.content,
        { opacity: 0 },
        {
          delay: 0.5,
          duration: 1,
          opacity: 1,
          ease: Back.easeInOut // Specify an ease
        }
      );
    },
    async rotateLine() {
      if (!this.onMenu) return;
      this.currentStep += 1;
      this.lineHorizontalAnim.to(this.$refs.lineHorizontal, 1, {
        rotation: 90 * this.currentStep + 45,
        ease: Back.easeInOut // Specify an ease
      });
      this.lineVerticalAnim.to(this.$refs.lineVertical, 1, {
        rotation: 90 * this.currentStep - 45,
        ease: Back.easeInOut // Specify an ease
      });
    },
    onClick(key) {
      this.$refs["key-word"].innerHTML = this.letter[key];
      this.keyWord.restart(true);
      this.onMenu = false;
      gsap.set(this.$refs.lineVertical, {
        width: 1,
        height: "100vh",
        left: "50%",
        rotate: 0
      });
      gsap.set(this.$refs.lineHorizontal, {
        height: 1,
        width: "100vw",
        rotate: 0
      });
      gsap.to(this.$refs.lineVertical, {
        height: 0,
        top: 0
      });
      gsap.to(this.$refs.lineHorizontal, {
        width: 0,
        left: 0
      });
      gsap.to(this.$refs.content, {
        width: "95%",
        height: this.isMobile ? "80%" : "95%",
        ease: Back.easeInOut // Specify an ease
      });
    },
    onClickKeyword() {
      console.log(this.keyWord);
      if (this.keyWord.word === "Key") this.$router.push("/key");
    }
  }
};
</script>

<style lang="scss" scoped>
.intro-section {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  button {
    border: none;
    background: none;
    color: white;
    font-size: 48px;
    cursor: pointer;
    min-width: 80px;
    font-family: "Josefin Sans", sans-serif;
    @media screen and (max-width: 600px) {
      font-size: 24px;
    }
  }
  button:focus {
    outline: none;
  }
  &__content {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 88vh;
    height: 88vh;
    color: white;
    z-index: 2;
    @media screen and (max-width: 600px) {
      width: 88vw;
      height: 88vw;
    }
    .intro-section__content-middle {
      width: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
    }
  }
  &__keyword-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .key-word {
      font-size: 86px;
      z-index: 2;
      @media screen and (max-width: 600px) {
        font-size: 48px;
        width: 300px;
        transform: rotate(90deg);
        flex-grow: 1;
        overflow: hidden;
        line-height: 2;
      }
    }
  }
  &__line {
    transition: all 0.5 ease-in-out;
    position: absolute;
    top: 50vh;
    left: calc(50% - 60vh);
    width: 120vh;
    color: white;
    height: 0.5px;
    background-color: white;
    box-shadow: 0 0 5px white, 1px 1px 5px white, 2px 2px 5px white,
      -1px -1px 5px white, -2px -2px 5px white;
    @media screen and (max-width: 600px) {
      width: 120vw;
      left: calc(50% - 60vw);
    }
  }

  span {
    color: white;
    display: inline-block;
    text-align: center;
    font-weight: 100;
    display: block;
    text-shadow: 0 0 10px white, 1px 1px 10px white, 2px 2px 10px white,
      -1px -1px 10px white, -2px -2px 10px white;
  }
}
</style>
